/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAlert } from "react-alert";
import {
  AlertTriangle,
  Calendar,
  CheckCircle,
  Edit,
  Info,
  Mail,
  MessageSquare,
  MinusCircle,
  Plus,
  User,
  XCircle,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { Form } from "@unform/web";
import axios from "axios";
import { format } from "date-fns";
import * as Yup from "yup";

import { ReactComponent as IconMonitoring } from "~/assets/icons/icon-monitoring.svg";
import {
  Button,
  Container,
  DatePicker,
  Flag,
  Input,
  Forbbiden,
  Loading,
  Modal,
  P1,
  P2,
  P3,
  RiskLevel,
  Table,
  TableRow,
  Tooltip,
  Upload,
} from "~/components";
import PopupViewer from "~/components/PopupViewer";
import PopupWrapper from "~/components/PopupWrapper";
import { formatErrorMessage, reduce, useApi, useFetch } from "~/hooks";
import { parseISO } from "~/hooks/helpers";
import { ResultHead } from "~/layout";
import { error, useMe } from "~/services";

import { table } from "./data";
import {
  ActionMonitoring,
  ActionsButton,
  ActionsIcon,
  Card,
  ModalMessage,
  ModalView,
  View,
} from "./styles";

const ActionPlanResponse = () => {
  const { user } = useMe();
  const { id } = useParams();
  const { search } = useLocation();
  const alert = useAlert();
  const navigate = useNavigate();
  const { t } = useTranslation("actionplan");
  const { t: tR } = useTranslation("results");
  const { t: tC } = useTranslation("card");
  const { t: tAuth } = useTranslation("auth");
  const { t: tD } = useTranslation("diligence");
  const token = new URLSearchParams(search).get("jwt");
  const api = useApi();

  const [loading, onLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, onShowPopup] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [checkResponse, setCheckResponse] = useState(false);
  const [rowsDiligence, onRowsDiligence] = useState([]);
  const [sortDiligences, onSortingDiligences] = useState({});
  const [monitoring, onMonitoring] = useState(false);
  const [selectedProcessId, onSelectedProcessId] = useState();
  const [modal, setModal] = useState({});

  const popupWrapperRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => popupWrapperRef.current,
  });

  const ref = useRef(null);
  useEffect(() => {
    if (!id) {
      localStorage.clear();
    }
  }, [id]);

  const customFetcher = async (params) => {
    const guest = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
      headers: {
        Authorization: `Bearer ${token}`,
        locale: localStorage.getItem("i18nextLng"),
        contentType: "application/json",
      },
    });
    const response = await guest.get(params);
    return response.data;
  };

  const { data: isInvited, mutate: refresh } = useFetch({
    url: "/actionplan/answer",
    customFetcher,
  });
  const {
    data: isUser,
    mutate,
    isValidating,
  } = useFetch({ url: id ? `/actionplan/${id}` : null });
  console.log(isValidating);
  const { data: diligences, mutate: renderDiligences } = useFetch({
    url: `/result/actionplan?&search=${
      isUser ? isUser?.data?.action_plan?.process_id : ""
    } 
    ${isInvited ? isInvited?.data?.action_plan?.process_id : ""}`,
  });
  const popups = useMemo(
    () => (popupData ? JSON.parse(popupData) : popupData),
    [popupData]
  );

  const data = useMemo(() => {
    if (isUser) {
      return isUser;
    }
    if (isInvited) {
      return isInvited;
    }
    return null;
  }, [isUser, isInvited]);

  // Create table diligence.
  const diligence = useCallback(async () => {
    let row = null;

    if (diligences?.data?.length === 0) {
      row = [];
    }

    if (diligences?.data?.length > 0) {
      row = diligences?.data?.map((item) => {
        const deleted = async () => {
          await api()
            .delete(`/monitoring/${item.monitoring_id}`)
            .then(() => {
              renderDiligences();
              alert.success(t("alerts.monitoring.success"));
            })
            .catch(({ response }) =>
              alert.error(
                formatErrorMessage(
                  response,
                  t("alerts.monitoring.error"),
                  tAuth("expired")
                )
              )
            );
        };

        const toMonitor = [
          {
            icon: (
              <ActionMonitoring actived={item?.monitoring}>
                <IconMonitoring />
              </ActionMonitoring>
            ),
            action: () => {
              if (!item?.monitoring) {
                onMonitoring({ create: true });
                onSelectedProcessId(item.id);
              } else if (!item?.monitoring?.deleted_by) {
                deleted();
              }
            },
          },
        ];

        const isArrow = true;

        return {
          id: item.id,
          items: [
            {
              label: (
                <Tooltip
                  label={
                    <>
                      {`${tR("evaluations.tooltips.info.0")} ${
                        item.supplier_code || tR("evaluations.tooltips.info.1")
                      }`}
                      <br />
                      {tR("evaluations.tooltips.info.2")}
                      {item.show_id}
                    </>
                  }
                  width={260}
                >
                  <Info />
                </Tooltip>
              ),
            },
            {
              label: item.razaosocial || item.nomefantasia,
              isBold: true,
            },
            {
              label: item.cnpj,
            },
            {
              label: (
                <RiskLevel
                  id={item.risk?.probabilidade_id}
                  risk={Math.round(Number(item.risk?.risk))}
                />
              ),
            },
            {
              label: item.dataabertura
                ? format(parseISO(item.dataabertura), "dd/MM/yyyy")
                : "-",
            },
            {
              label: item?.workflows.map((child) => child.name) || "",
              isMulti: true,
            },
            {
              label: (
                <Flag id={item.new_status.id} status={item.new_status.name} />
              ),
            },
            {
              label:
                item.tags.length > 0 ? (
                  <Flag status={item.tags[0].name} />
                ) : (
                  " "
                ),
            },
            {
              isArrow,
              notArrowMessage:
                !isArrow && item?.analyst?.nome
                  ? `${tC("admin_operations.being_edited_by")} ${
                      item?.analyst?.nome
                    } (${item?.analyst?.id})`
                  : null,
            },
          ],
        };
      });
    }

    onRowsDiligence(row);
  }, [alert, diligences?.data, renderDiligences, t, user?.usuario?.id, tAuth]);

  useEffect(() => {
    diligence();
  }, [diligence]);

  const tabled = {
    diligencias: {
      body: rowsDiligence,
      head: table.diligencias(tR, onSortingDiligences),
      route: "/pesquisa/diligencia",
      onBody: onRowsDiligence,
    },
  };

  const { actions, action_plan } = data?.data ?? {
    actions: null,
    action_plan: null,
  };
  const historyRows = useMemo(() => {
    let checkLabel = false;
    const localHistoryRows = action_plan?.history?.map((item) => {
      let label = "";
      const date = format(parseISO(item.created_at), "dd/MM/yyyy");
      switch (item.action_id) {
        case 1:
          label = t("detail.status.approved");
          checkLabel = true;
          break;
        case 2:
          label = t("detail.status.refused");
          checkLabel = false;
          break;
        case 3:
          label = t("detail.status.reminder");
          checkLabel = false;
          break;
        case 4:
          label = t("detail.status.comment");
          checkLabel = false;
          break;
        case 5:
          label = t("detail.status.responsible");
          checkLabel = false;
          break;
        case 6:
          label = t("detail.status.due_date");
          checkLabel = false;
          break;
        case 7:
          label = t("detail.status.cancel");
          checkLabel = true;
          break;
        case 8:
          label = t("detail.status.answered");
          checkLabel = true;
          break;
        default:
          break;
      }
      return [
        { payload: label, type: "TEXT" },
        { payload: item.note || "-", type: "TEXT" },
        { payload: date, type: "TEXT" },
        {
          payload: item.attachment_name
            ? item.attachment_name
            : t("detail.data.not_have"),
          type: "TEXT",
        },
        {
          payload: item.attachment_name
            ? {
                types: ["FILE"],
                fileAction: {
                  onClick: () => {
                    window.open(item.attachment_url, "_blank");
                  },
                },
              }
            : 1,
          type: item.attachment_name ? "BUTTONS" : "HEAD_BUTTONS",
        },
      ];
    });

    setCheckResponse(checkLabel);
    return localHistoryRows;
  }, [action_plan, t]);

  if (!data?.data && !isValidating) {
    return <Forbbiden />;
  } else if (!data?.data && isValidating) {
    return <Loading />;
  }

  let i = 1;
  const resultColumns = [];
  if (action_plan.result) {
    while (action_plan.result[`coluna${i}`]) {
      resultColumns.push({
        payload: action_plan.result[`coluna${i}`],
        type: "TEXT",
      });
      i += 1;
    }
  } else {
    resultColumns.push({
      payload: action_plan.subject,
      type: "TEXT",
    });
  }

  if (action_plan.result) {
    resultColumns.push({
      payload: {
        types: action_plan.result.popup ? ["FILE", "REDIRECT"] : ["REDIRECT"],
        fileAction: {
          onClick: () => {
            setPopupData(action_plan.result.popup);
            setPopupTitle(
              action_plan.subject
                .split("-")
                .slice(1, action_plan.subject.length)
                .join(": ") || t("detail.not_risk")
            );
            onShowPopup(true);
          },
        },
      },
      type: "BUTTONS",
    });
  }

  const infoData = [
    {
      title: t("detail.data.responsible"),
      value: action_plan.responsible_name,
    },
    { title: t("detail.data.requester"), value: action_plan.dd_owner.nome },
    {
      title: t("detail.data.due_date"),
      value: format(parseISO(action_plan.due_date), "dd/MM/yyyy"),
    },
    {
      title: t("detail.data.anex"),
      value: action_plan.attachment_name ? (
        <P2
          as="a"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href={action_plan.attachment_url}
          title={action_plan.attachment_name}
        >
          {reduce(action_plan.attachment_name, 16)}
        </P2>
      ) : (
        t("detail.data.not_have")
      ),
    },
    { title: t("detail.data.email"), value: action_plan.responsible_email },
    { title: t("detail.data.email"), value: action_plan.dd_owner.email },
    {
      title: t("detail.data.opened"),
      value: format(parseISO(action_plan.created_at), "dd/MM/yyyy"),
    },
  ];

  const isAvailible = ![2, 3].includes(action_plan.status_id);
  const actionButtonItems = actions.map((action) => {
    let icon = <></>;
    let label = "";
    const actionModal = { inputs: [] };

    switch (action.id) {
      case 1:
        icon = <CheckCircle />;
        label = t("detail.actions.approved.label");
        actionModal.description = t("detail.actions.approved.description");
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 2:
        icon = <MinusCircle />;
        label = t("detail.actions.refused.label");
        actionModal.description = t("detail.actions.refused.description");
        actionModal.mandatoryNote = true;
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 3:
        icon = <Mail />;
        label = t("detail.actions.reminder.label");
        actionModal.description = t("detail.actions.reminder.description");
        actionModal.mandatoryNote = true;
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 4:
        icon = <MessageSquare />;
        label = t("detail.actions.comment.label");
        actionModal.description = t("detail.actions.comment.description");
        actionModal.mandatoryNote = true;
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 5:
        icon = <User />;
        label = t("detail.actions.responsible.label");
        actionModal.description = t("detail.actions.responsible.description");
        actionModal.inputs = ["responsible_name", "responsible_email"];
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 6:
        icon = <Calendar />;
        label = t("detail.actions.due_date.label");
        actionModal.description = t("detail.actions.due_date.description");
        actionModal.inputs = ["due_date"];
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      case 7:
        icon = <XCircle />;
        label = t("detail.actions.cancel.label");
        actionModal.description = (
          <ModalMessage>
            <AlertTriangle size="1.4rem" />
            <span>{t("detail.actions.cancel.description")}</span>
          </ModalMessage>
        );
        actionModal.title = t("detail.actions.cancel.button");
        actionModal.actionId = action.id;
        actionModal.buttonText = t("detail.actions.cancel.button"); // eslint-disable-line
        actionModal.messageCancel = true;
        break;
      case 8:
        icon = <Edit />;
        label = t("detail.actions.answered.label");
        actionModal.description = t("detail.actions.answered.description");
        actionModal.mandatoryNote = true;
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
      default:
        actionModal.title = label;
        actionModal.actionId = action.id;
        actionModal.buttonText = label.split(" ")[0]; // eslint-disable-line
        actionModal.messageCancel = false;
        break;
    }

    return {
      id: action.id,
      icon,
      label,
      modal: actionModal,
    };
  });

  if (!isInvited) {
    actionButtonItems.pop();
  }

  const handleSubmit = async (formData) => {
    onLoading(true);
    const {
      upload,
      actionId,
      due_date,
      responsible_email,
      responsible_name,
      ...formDataRest
    } = formData;
    if (formData.note.length > 1000) {
      alert.error(tD("seem.characteres_over_limit"));
      onLoading(false);
    } else {
      const action_id = Number(actionId);
      const message = {
        success: t("detail.messages.general.success"),
        error: t("detail.messages.general.error"),
      };
      let request = formDataRest;
      switch (action_id) {
        case 5:
          request = { responsible_email, responsible_name, ...formDataRest };
          message.success = "Responsável alterado com sucesso";
          message.error = "Falha ao alterar o responsável";
          break;
        case 6:
          request = {
            due_date: due_date ? format(due_date, "yyyy-MM-dd") : due_date,
            ...formDataRest,
          };
          message.success = t("detail.messages.due_date.success");
          message.error = t("detail.messages.due_date.error");
          break;
        case 8:
          message.success = t("detail.messages.answered.success");
          message.error = t("detail.messages.answered.error");
          break;
        default:
          break;
      }

      if (action_id === 8) {
        await api()
          .post("/actionplan/answer", request, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            alert.success(message.success);
            setShowModal(false);
            refresh();
            if (upload) {
              const form = new FormData();
              form.append("attachment", upload);
              api()
                .post(
                  `/actionplan/answer/${res.data.data.action.id}/attachment`,
                  form,
                  { headers: { Authorization: `Bearer ${token}` } }
                )
                .then(() => {
                  mutate();
                })
                .catch(({ response }) => {
                  alert.error(
                    formatErrorMessage(
                      response,
                      t("detail.messages.upload.error"),
                      tAuth("expired")
                    )
                  );
                });
            }
          })
          .catch(({ response }) => {
            alert.error(
              formatErrorMessage(response, message.error, tAuth("expired"))
            );
          });
      } else {
        Yup.object()
          .shape({
            responsible_email:
              action_id === 5
                ? Yup.string()
                    .email(t("detail.fields.responsible_email.valid"))
                    .required(t("detail.fields.responsible_email.required"))
                : undefined,
            responsible_name:
              action_id === 5
                ? Yup.string().required(
                    t("detail.fields.responsible_name.required")
                  )
                : undefined,
            note: Yup.string().required(t("detail.messages.note")),
            due_date:
              action_id === 6
                ? Yup.string().required(t("detail.fields.due_date.required"))
                : undefined,
          })
          .validate(formData, {
            abortEarly: false,
          })
          .then(async () => {
            await api()
              .post(`/actionplan/${id}/${actionId}`, request)
              .then(async (res) => {
                setShowModal(false);
                alert.success(message.success);
                await mutate();
                if (upload) {
                  const form = new FormData();
                  form.append("attachment", upload);
                  api()
                    .post(
                      `/actionplan/${id}/${res.data.data.action.id}/attachment`,
                      form
                    )
                    .then(() => {
                      mutate();
                    })
                    .catch(({ response }) => {
                      alert.error(
                        formatErrorMessage(
                          response,
                          t("detail.messages.upload.error"),
                          tAuth("expired")
                        )
                      );
                    });
                }
              })
              .catch(({ response }) => {
                alert.error(
                  formatErrorMessage(response, message.error, tAuth("expired"))
                );
              });
          })
          .catch((err) => {
            error(err, ref);
          });
      }
    }
    onLoading(false);
  };

  return (
    <Container>
      <View>
        <ResultHead
          hasArrow
          title={`[${action_plan.process.id}] ${reduce(
            action_plan.process.razaosocial,
            32
          )} - ${action_plan.process.cnpj}`}
        >
          <ActionsButton>
            {!isInvited && isAvailible && !user?.isVisualizador() ? (
              <>
                <Button
                  label={t("detail.action.primary")}
                  iconLeft={<Plus />}
                />
                <div>
                  {actionButtonItems.map((action) => (
                    <>
                      <P3
                        key={action.id}
                        as="button"
                        onClick={() => {
                          setModal(action.modal);
                          setShowModal(true);
                        }}
                      >
                        <ActionsIcon>{action.icon}</ActionsIcon>
                        {action.label}
                      </P3>
                    </>
                  ))}
                </div>
              </>
            ) : !checkResponse ? (
              !user?.isVisualizador() ? (
                <Button
                  label={t("detail.action.answered")}
                  onClick={() => {
                    setModal(actionButtonItems[7].modal);
                    setShowModal(true);
                  }}
                />
              ) : null
            ) : (
              <Flag status={t("detail.messages.status")} />
            )}
          </ActionsButton>
        </ResultHead>

        <Modal
          title={modal.title}
          messageCancel={modal.messageCancel}
          show={showModal}
          onShow={setShowModal}
          action={{
            label: modal.buttonText,
            onClick: async () => {
              onLoading(true);
              ref.current.submitForm();
            },
            loading,
          }}
        >
          <ModalView>
            <Form ref={ref} onSubmit={handleSubmit}>
              <P2>{modal.description}</P2>

              <Input reveal={false} value={modal.actionId} name="actionId" />

              {modal.inputs?.includes("responsible_name") && (
                <Input
                  name="responsible_name"
                  label={t("detail.fields.responsible_name.label")}
                  appearance="secondary"
                  placeholder={t("detail.fields.responsible_name.placeholder")}
                  reveal={modal.inputs?.includes("responsible_name")}
                />
              )}

              {modal.inputs?.includes("responsible_email") && (
                <Input
                  name="responsible_email"
                  label={t("detail.fields.responsible_email.label")}
                  appearance="secondary"
                  placeholder={t("detail.fields.responsible_email.placeholder")}
                  reveal={modal.inputs?.includes("responsible_email")}
                />
              )}

              {modal.inputs?.includes("due_date") && (
                <DatePicker
                  name="due_date"
                  label={t("detail.fields.due_date.label")}
                  appearance="secondary"
                  placeholder={t("detail.fields.due_date.placeholder")}
                  reveal={modal.inputs?.includes("due_date")}
                  minDate={new Date()}
                />
              )}

              <Input
                name="note"
                label={`${t("detail.fields.note.label")} ${
                  modal.mandatoryNote ? t("detail.fields.note.required") : ""
                }`}
                appearance="secondary"
                placeholder={t("detail.fields.note.placeholder")}
                textarea
                maxLength={1000}
              />

              <Upload
                name="upload"
                label={t("detail.fields.upload")}
                isDocument
              />
            </Form>
          </ModalView>
        </Modal>

        <Card>
          <header>
            {action_plan.subject.split("-").length > 1 ? (
              <P1 isBold>{`${action_plan.subject.split("-")[0]} - ${
                action_plan.subject.split("-")[1]
              }`}</P1>
            ) : (
              <P1 isBold>{action_plan.subject}</P1>
            )}
            <Flag status={action_plan.status.name} />
          </header>
          <div>
            {resultColumns.length > 1 ? (
              <TableRow
                columns={resultColumns}
                redirect={() =>
                  navigate(`/pesquisa/diligencia/${action_plan.process.id}`)
                }
              />
            ) : (
              <Table
                body={tabled.diligencias.body}
                route={tabled.diligencias.route}
                onBody={tabled.diligencias.onBody}
                heading={tabled.diligencias.head}
                breakpoint="md"
              />
            )}
          </div>

          <article>
            <P1>{t("detail.information.title")}</P1>
            <div>
              {infoData.map((item) => (
                <P2 key={item.title} title={item.value}>
                  <span>{`${item.title}: `}</span>
                  {item?.value
                    ? reduce(item.value, 56)
                    : t("detail.information.not_have")}
                </P2>
              ))}
            </div>
            <P2>
              <span>{`${t("detail.information.description")} `}</span>
              {action_plan.description}
            </P2>
          </article>

          {historyRows.length > 0 && (
            <article>
              <P1>{t("detail.moves.title")}</P1>
              <table>
                <thead>
                  <TableRow
                    columns={[
                      {
                        payload: t("detail.moves.columns.action"),
                        type: "HEAD",
                      },
                      {
                        payload: t("detail.moves.columns.observation"),
                        type: "HEAD",
                      },
                      {
                        payload: t("detail.moves.columns.date"),
                        type: "HEAD",
                      },
                      {
                        payload: t("detail.moves.columns.anex"),
                        type: "HEAD",
                      },
                      { payload: 1, type: "HEAD_BUTTONS" },
                    ]}
                  />
                </thead>
                <tbody>
                  {historyRows?.map((historyColumns) => (
                    <TableRow
                      key={historyColumns[2]} // document
                      columns={historyColumns}
                      sentenceMaxLength={250}
                      dataLabels={[
                        t("detail.moves.columns.action"),
                        t("detail.moves.columns.observation"),
                        t("detail.moves.columns.date"),
                        t("detail.moves.columns.anex"),
                      ]}
                    />
                  ))}
                </tbody>
              </table>
            </article>
          )}
        </Card>

        <Modal
          title={popupTitle}
          show={showPopup}
          onShow={onShowPopup}
          hasActions={false}
          size="lg"
          hasPrinterIcon
          onClickPrinter={handlePrint}
        >
          <PopupWrapper ref={popupWrapperRef}>
            <PopupViewer popup={popups} />
          </PopupWrapper>
        </Modal>
      </View>
    </Container>
  );
};

export default ActionPlanResponse;
