import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { format, isBefore } from 'date-fns';

import {
  Flag,
  Table,
  Empty,
  Helmet,
  Paginate,
  Container,
  SimpleInput,
  SimpleLoading,
  RangeDatePicker,
} from '~/components';
import ClientSelect from '~/components/ClientSelect';
import { useFetch, useDebounce } from '~/hooks';
import { parseISO } from '~/hooks/helpers';
import { HeaderBack, ModalMonitoring } from '~/layout';
import { useMe } from '~/services';

import table from './data';
import { View, Wrapper, ActionsHeader } from './styles';

const ActionPlan = () => {
  const { user, group, newGroup } = useMe();

  const { t } = useTranslation('actionplan');

  const [page, onPage] = useState(1);
  const [date, onDate] = useState('');
  const [size, onSize] = useState({ value: 50, label: 50 });
  const [search, onSearch] = useState('');
  const [edit, onEdit] = useState(false);
  const [rows, onRows] = useState([]);

  if (Object.entries(group).length === 0) {
    newGroup({
      value: user.hierarquia[0].id,
      label: user.hierarquia[0].cliente,
    });
  }
  const [endDate, onEndDate] = useState('');
  const [sorting, onSorting] = useState({});

  const due_date = useMemo(() => ({
    date: date ? format(date, 'yyyy-MM-dd') : '',
    endDate: endDate ? format(endDate, 'yyyy-MM-dd') : '',
  }), [date, endDate]);

  const jump = useDebounce(page);
  const debounced = useDebounce(search);

  const { data, mutate } = useFetch({
    url: `/actionplan?page=${jump}${group.value === 0 ? '' : `&clientId=${group.value}`}&pageSize=${size.value}
    ${debounced ? `&searchField=${debounced}` : ''}
    ${due_date.date ? `&from=${due_date.date}` : ''}
    ${due_date.endDate ? `&from=${due_date.endDate}` : ''}
    ${`&order=${JSON.stringify(sorting)
    .replace('0', 'entity')
    .replace('1', 'process')
    .replace('2', 'opening_date')
    .replace('3', 'due_date')
    .replace('4', 'responsible_name')
    .replace('5', 'subject')
    .replace('6', 'status')
  }`}
    `,
  });

  /**
   * Create row
   * actionplan table.
   */
  const row_actionplan = useCallback(() => {
    let row = [];

    if (data !== undefined && data.length <= 0) {
      row = [];
      return;
    }

    row = data?.data?.map((item) => ({
      id: item.id,
      items: [
        {
          label: item.corporate_name || '-',
          isBold: true,
        },
        {
          label: item.process_id || '-',
        },
        {
          label: item.updated_at
            ? format(parseISO(item.updated_at), 'dd/MM/yyyy')
            : '-',
        },
        {
          label: item.due_date
            ? format(parseISO(item.due_date), 'dd/MM/yyyy')
            : '-',
          className: isBefore(new Date(item?.due_date), new Date().setDate(new Date().getDate() - 1)) ? 'is-due-date' : undefined,
        },
        {
          label: item.responsible_name || '-',
        },
        {
          label: item.subject || '-',
        },
        {
          label: <Flag status={item?.status_name || '-'} />,
        },
        {
          isArrow: true,
        },
      ],
    }));

    onRows(row);
  }, [data]);

  useEffect(() => {
    row_actionplan();
  }, [row_actionplan]);

  return (
    <View>
      <Helmet title={t('table.title')} />

      <Container>
        <HeaderBack title={t('table.title')} hasArrow={false}>
          <ActionsHeader>
            <ClientSelect
              user={user}
              value={group}
              onChange={(val) => newGroup(val)}
              placeholder={t('table.group')}
            />

            <RangeDatePicker
              date={date}
              onDate={onDate}
              endDate={endDate}
              onEndDate={onEndDate}
            />

            <SimpleInput
              isSearch
              name="search"
              icon={<Search />}
              value={search}
              onValue={onSearch}
              onChange={(e) => onSearch(e.target.value)}
              appearance="secondary"
              placeholder={t('table.search')}
            />
          </ActionsHeader>
        </HeaderBack>

        <Wrapper>
          {data ? data?.data?.length > 0 && (
            <Table
              body={rows}
              route="/plano-de-acao"
              onBody={onRows}
              heading={table(t, onSorting)}
              breakpoint="md"
            />
          ) : <SimpleLoading />}

          {data?.data?.length > 0 && (
            <Paginate
              size={size}
              pages={data?.last_page}
              total={data?.total}
              onSize={onSize}
              perPage={data?.data?.length}
              className="m-auto m-t-48"
              onPaginate={onPage}
              currentPage={page}
            />
          )}

          {data?.data?.length === 0 && <Empty />}
        </Wrapper>
      </Container>

      <ModalMonitoring
        type="edit"
        show={edit}
        render={mutate}
        onShow={onEdit}
        hasDelete
      />
    </View>
  );
};

export default ActionPlan;
