import React, { useState } from 'react';
import { Paperclip } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { P2, H1 } from '~/components';
import { parseISO, reduce } from '~/hooks';

import { useResultData } from '../../resultContext';
import { CardView, Seem, SectionView as View } from '../../styles';

const DiligenceSeem = () => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation('diligence');
  const { seem } = useResultData();

  const hasShowMoreOption = seem?.data?.note?.length > 248
    || (!! seem?.data?.attachment_url && !! seem?.data?.attachment_name);

  return ! seem?.data?.id ? null
    : (
      <View>
        <H1>{t('seem.title')}</H1>
        <CardView>
          <Seem>
            <div>
              <P2 as="span">
                {t('seem.fields.0')}
                <span>
                  {reduce(seem?.data?.user?.nome ?? '-', 248)}
                </span>
              </P2>
              <P2 as="span">
                {t('seem.fields.1')}
                <span>
                  {seem?.data?.created_at ? format(parseISO(seem?.data?.created_at), 'dd/MM/yyyy') : '-'}
                </span>
              </P2>
            </div>
            <div>
              <P2 as="span">
                {t('seem.fields.2')}
                <span>
                  {opened ? seem?.data?.note ?? '' : reduce(seem?.data?.note ?? '', 248)}
                </span>

                {opened && (
                  <a
                    rel="noreferrer"
                    href={seem?.data?.attachment_url}
                    target="_blank"
                  >
                    <Paperclip />
                    {`${t('seem.download')} ${seem?.data?.attachment_name}`}
                  </a>
                )}

                {
                  hasShowMoreOption
                  && (
                    <p
                      role="presentation"
                      onClick={() => setOpened(! opened)}
                    >
                      {`${opened ? t('seem.less') : t('seem.more')}`}
                    </p>
                  )
                }
              </P2>
            </div>
          </Seem>
        </CardView>
      </View>
    );
};
export default DiligenceSeem;
