import styled, { css } from 'styled-components';

import {
  breakpoints, colors, easing, typograph,
} from '~/styles';

const { gray, status } = colors;

const { basic } = easing;

const { size: font, weight } = typograph;

const sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxs: 'xxs',
  xxl: 'xxl',
};

const aligns = {
  left: 'left',
  right: 'right',
  center: 'center',
};

export const TR = styled.tr`
  transition: all 0.3s ${basic};
  background-color: ${gray._000};

  ${({ isDisabled }) => isDisabled
    && css`
      background-color: rgba(255, 255, 255, 0.5) !important;
      > *:not(:last-of-type) {
        opacity: 0.5;
      }
      > *:last-of-type button:first-of-type {
        opacity: 0.5;
        pointer-events: none !important;
      }
    `}
`;

export const Item = styled.td`

  ${({ leftSpacing }) => leftSpacing && css`
    padding-left: ${leftSpacing}rem !important;
  `}

  ${({ isBold }) => isBold
    && css`
      font-weight: ${weight.bold} !important;
    `}

  ${({ isMulti }) => isMulti
    && css`
      & > div {
        display: flex;
        align-items: center;

        & > *:not(:first-child) {
          margin-left: 1.2rem;
        }
      }
    `}

  /**
   * Align table.
   */
  ${({ align }) => align === aligns.left
    && css`
      text-align: left;
    `}

  ${({ align }) => align === aligns.center
    && css`
      text-align: center !important;
    `}

  ${({ align }) => align === aligns.right
    && css`
      text-align: right;
    `}

  /**
   * Sizes table.
   */
  ${({ size }) => size === sizes.xxs
    && css`
      width: 10%;
    `}

  ${({ size }) => size === sizes.xs
    && css`
      width: 20%;
    `}

  ${({ size }) => size === sizes.sm
    && css`
      width: 35%;
    `}

  ${({ size }) => size === sizes.md
    && css`
      width: 55%;
    `}

  ${({ size }) => size === sizes.lg
    && css`
      width: 65%;
    `}

  ${({ size }) => size === sizes.xl
    && css`
      width: 80%;
    `}

  ${({ size }) => size === sizes.xxl
    && css`
      width: 100%;
    `}

  ${({ size }) => Number.isInteger(size)
    && css`
      width: ${size}%;
    `}

  ${({ fontSize }) => Object.keys(font).includes(fontSize)
    && css`
      font-size: ${font[fontSize]} !important;
    `}

  ${({ height }) => Number.isInteger(height)
    && css`
      height: ${height}rem !important;
    `}
`;

export const View = styled.table`
  width: 100%;
  border-spacing: 0;

  thead {
    ${Item} {
      color: ${gray._500};
      padding: 1.6rem 2rem;
      font-size: ${font.s1};
      text-align: left;
      line-height: ${font.s3};

      & > span {
        display: flex;
        align-items: center;

        svg {
          cursor: pointer;
          min-width: 1.4rem;
        }

        & > div,
        & > svg {
          display: flex;
          margin-left: 1.2rem;
        }
      }
    }
  }
  .accordeonTable {
    padding-left: 80px;
  }
  tbody tr {
    ${Item} {
      color: ${gray._600};
      padding: 0 2rem;
      height: 6rem;
      font-size: ${font.s2};
      line-height: ${font.s3};
      font-weight: ${typograph.weight.regular};

      svg {
        width: 1.9rem;
        color: ${colors.gray._500};
        margin: 0 auto;
        display: flex;
        align-items: center;
      }

      svg.disabled {
        stroke: #e6e4e4 !important;
        cursor: not-allowed !important;
      }

      svg.reactive {
        stroke: ${status.success} !important;
      }

      svg.monitoring {
        width: 2.8rem;
      }

      &.is-due-date {
        color: ${status.error} !important;
        font-weight: ${weight.bold} !important;
      }
    }

    td.arrow {
      cursor: pointer;
    }

    td.especial {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 10rem !important;
    }

    span.success,
    td.success {
      svg {
        color: ${colors.status.success} !important;
      }
    }

    span.warning,
    td.warning {
      svg {
        color: ${colors.primary._000} !important ;
      }
    }

    span.error,
    td.error {
      svg {
        color: ${colors.status.error} !important;
      }
    }

    &:not(:first-child) td {
      border-top: 0.4rem solid ${gray._100};
    }
  }

  @media screen and (max-width: ${breakpoints.xl}) {
    thead ${Item} {
      padding: 1.6rem 1rem;
    }

    tbody tr ${Item} {
      padding: 0rem 1rem;
      height: 6rem !important;
    }
  }

  @media screen and (max-width: ${({ breakpoint }) => breakpoints[breakpoint]}) {
    thead {
      display: none;
    }

    &,
    & tbody,
    & tr,
    & td {
      display: block;
      width: 100%;
      text-align: right;
      border-top: 0 !important;
    }

    .accordeonTable {
      padding-left: 0px;
    }

    tbody tr {
      &:not(:first-of-type) {
        border-top: 0.8rem solid ${gray._100} !important;
      }

      ${Item} {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        position: relative;
        padding: 0rem 2rem;
        height: 5rem !important;
        font-weight: ${weight.regular} !important;

        &::before {
          content: attr(data-label);
          color: ${gray._600} !important;
          font-weight: ${weight.bold};
          position: absolute;
          left: 0;
          width: 50%;
          text-align: left;
          padding: 0 2rem;
        }

        &.especial {
          width: 100% !important;
          justify-content: center;
        }

        &.arrow {
          svg {
            transform: translateX(4rem);
          }

          &::before {
            text-align: right;
          }
        }
      }
    }
  }
`;
